import { ref, computed, unref } from 'vue'
import { remove } from 'lodash-es'

export const useSelector = (
    options,
    searchableFields,
    optionValue,
    inputValue,
    isMultiple
) => {
    const results = ref([])

    const currentOptions = computed(() => {
        const isTyped = unref(inputValue).length
        const isSelected = results.value && results.value.length
        const rawOptions = unref(options)

        if (!isTyped || (!isMultiple && isSelected)) {
            return rawOptions
        }

        return getMatchingOptions(
            rawOptions,
            searchableFields,
            inputValue.value
        )
    })

    const addResultItem = (option) => {
        results.value.push(option)
    }

    const removeResultItem = (option) => {
        remove(
            results.value,
            (selection) => selection[optionValue] === option[optionValue]
        )
    }

    const replaceResults = (options) => {
        results.value = options
    }

    const clearResults = () => {
        results.value = []
    }

    const isSelected = (option) => {
        return (
            results.value &&
            results.value.some(
                (resultItem) => resultItem[optionValue] === option[optionValue]
            )
        )
    }

    return {
        results,
        currentOptions,
        addResultItem,
        removeResultItem,
        replaceResults,
        clearResults,
        isSelected,
    }
}

export const getMatchingOptions = (options, fields, query) => {
    if (!fields && !query) {
        return options
    }

    return options.filter((option) => {
        if (!query) {
            return true
        }

        return fields.some((field) => {
            if (!option[field]) {
                return false
            }

            let optionValue = option[field]
            if (!(option[field] instanceof String)) {
                optionValue = option[field].toString()
            }

            return optionValue.toLowerCase().includes(query.toLowerCase())
        })
    })
}
